import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './golf_101_list.css';

function Golf_101_list() {
    const [theme, setTheme] = useState('light');
    const toggleDarkMode = () => {
        if (theme === 'light') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    };
    useEffect(() => {
            document.body.className = theme;
          }, [theme]);

    <button onClick={toggleDarkMode}>Toggle Theme</button>

    return (
        <div className= {'golf_101_list ${theme}'}>
            <div className="btnWrapper">
            <button className="darkModeToggleBtn" onClick={() => toggleDarkMode()}><span className="text">Dark/Light Mode</span></button>
            </div>
            <header className="App-header">
                <div className="golf_101_list">
                    <ol>
                        <h2>  ⛳ 우리방 골프 매너 101 ⛳ </h2>
                        <li className='blink'>  저희 골프 방에서 라운딩 도실 때 “돈” 관련x 내기를 하시면 경고없이 퇴장입니다. 꼭 기억해주세요. </li>
                        <li>  첫 티 타임 30분 전에는 도착해주세요. 우리 인사도 나누고 연습도 해요.</li> 
                        <li>  늦는 상황 발생시에는 최대한 빨리 알려주세요. </li>
                        <li>  내가 치는 공이 어떤 건지 기억 해 주세요. </li>
                        <li>  보통 스코어가 제일 낮은 사람(Honor) 이 티 오프 먼저 하지만 상황에 따라 준비된 분 먼저하기.</li>
                        <li>  같이 치는 파트너나 주변에서 공 칠 때 조용히 하기. </li>
                        <li>  순서가 헷갈릴 시에는 홀컵에서 더 먼 사람이 먼저 치기. </li>
                        <li>  그린위에서 내 공이 다른분의 퍼팅 라인에 있다면 내 볼 마킹 하기. (내 퍼팅 라인 볼때도 도움이 되어요. 모른다면 여쭤보아요.) </li>
                        <li>  그린 위에서 다른 사람 공 이랑 홀 컵 사이 (라인) 걷지 않기. </li>
                        <li>  공 치고 나서 디봇 채워 주기. 카트에 보면 물병처럼 생긴 고운모래통 있습니다. </li>
                        <li>  그린위에 볼 마크가 생기면 디봇 툴 or 티 로 볼 마크 고쳐주세요. </li>
                        <li>  벙커 치고 나서 rake으로 모래 정리해주세요. </li>
                        <li>  혹시 내 공이 다른 골퍼 쪽으로 날아 가면은 꼭 크게 FORE ! 외쳐주세요, 그리고 꼭 사과해주세요. 안하시면 싸움 나요 ㅠㅠ </li>
                        <li>  그린 주변 50 야드 주변으로는 카트 타고 가지 마세요. </li>
                        <li>  파 3 홀은 Cart Path Only입니다. </li>
                        <li>  만약 너무 밀리는 경우에는 원활한 진행을 위해 공을 주워 주세요. 양파 (파 + 파 이상 되는 경우)일 시에는 홀아웃 해주세요. </li>
                        <li>  만약 같이 치는 파트너의 공이 숲이나 러프에 빠져 잘 안보이면 같이 찾아주세요. 혼자보단 둘이, 둘보다는 세명이 찾으면 더 빨리 찾을 수 있으니까요. </li>
                        <li>  스코어는 다음 티박스에서 준비중에 적어주세요. </li>
                        <li>  카트는 티 박스 옆에 주차해주세요. 티 박스 앞으로 가셔서 주차하시면 다칠수도 있어요. </li>
                        <li>  내 차례가 왔을 때 바로 칠 수 있게 준비해주세요. 파트너가 칠 때 카트에 계속 앉아만 계시면 진행이 느려지게 되어요. </li>
                        <li>  스윙 인터벌이 너무 길지 않게 해주세요. </li>
                        <li>  자책과 화내는 건 다릅니다. 공이 안 맞아서 기분이 안 좋을 수는 있지만 그렇다고 욕하기, 소리 지르기, 클럽 집어 던지기 등, 플레이 하는 파트너가 불편할 수 있는 행동은 하지 말아주세요. </li>
                        <li>  골프 라운딩 중에 같이 치는 파트너를 꼭 응원해주세요. 힘들어도 응원 받으면 기분 좋게 플레이 할 수 있잖아요. 그리고 모든 라운딩이 끝나면 “같이 쳐서 즐거웠습니다.” 라고 말해주세요. 사심 없는 격려가 즐거운 라운딩을 만듭니다. </li>
                        <li>  골프방 멤버분들에게 항상 예의 있게 말 해주세요. 아무리 개인적인 친분이 있으셔도 골프방에서는 조금 더 신경 써주세요. 저희 골프 방은 오픈 방이니까요. </li>
                    </ol>
                </div>
            </header>
        </div>
    );
}

/*
      manifest.json provides metadata used when your web app is installed on a
      user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/
*/

export default Golf_101_list;
