import React from 'react';
import './landingPage.scss';
import MenuIcon from './logo.png' 
import FallLeaf from './fallLeaf.png' 
import SnowFlake from './snowFlake.png'


function LandingPage() {
  return (

    <div className="bodyWrapper">
      <ul className="lightrope">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    <div className="landingPageWrapper">
      <span className="BangName"> 아틀란타 거침없는 골프 초보골프 백순이 백돌이 모여라 !</span>
      <div className="welcome_sign_fade">
        <h1> 환영합니다 <br /> 영어로 웰컴 </h1>
      </div>

      <div className="menu">
        <nav>
        <li className="menuList">
            <a href="/golf_101_list"><img className="golf_101_icon" src={MenuIcon} />
              <span className="golf_101_menu_text">골프 101 보러가기 </span></a>
        </li>        
        </nav>
        
      </div>
      </div>

{ <div className="snowflakes">
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
<div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>
<div className="snowflake">
  <div className="inner"><img className="snowFlake1" src={SnowFlake} /></div>
</div>

</div> 

/////////////////////////////////////////////////////////////////////FALL
/* <div className="fallLeaves">
<div className="fallLeaf">
<div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
<div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
<div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
<div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
<div className="fallLeaf">
  <div className="inner"><img className="fallLeaf1" src={FallLeaf} /></div>
</div>
</div>} */}
</div>


  )};
export default LandingPage;
